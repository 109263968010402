<template>
  <section id="BloquedosSecciones">
    <div class="bloque__izq">
      <div class="izq__titular">
        <div class="titular__encabezado">
          <p class="encabezado__texto">{{ primera_seccion.encabezado }}</p>
        </div>

        <div class="titular__descripcion">
          <span class="caracter_special">&#8220;</span>
          <p class="descripcion__texto" v-for="(texto, index) in primer_titular" :key="index">
          <span v-html="texto"></span>
          <span v-if="index == primera_seccion.titular.length - 1">&#8221;</span>
          </p>
        </div>
      </div>

      <div class="izq__contenido">
        <div class="contenido__informacion">
          <picture>
            <source :srcset="primera_seccion.imagen.webp" type="image/webp" />
            <img width="619" height="329" loading="lazy" class="informacion__imagen"
              :src="primera_seccion.imagen.default" :alt="primera_seccion.altTitles.alt"
              :title="primera_seccion.altTitles.title" />
          </picture>
          <p class="informacion__pieFoto" v-html="texto_imagen"></p>
          <p class="informacion__descripcion" v-for="texto in texto_descripcion" :key="texto" v-html="texto"></p>
        </div>
      </div>
    </div>

    <div class="bloque__der">
      <div class="der__titular">
        <div class="titular__encabezado">
          <p class="encabezado__texto">{{ segunda_seccion.encabezado }}</p>
        </div>

        <div class="titular__descripcion">
          <p class="descripcion__texto" v-for="texto in segundo_titular" :key="texto" v-html="texto"></p>
        </div>
      </div>

      <div class="der__contenido">
        <div class="contenido__contenedor" :class="{'contenedor--ePIL': ocultaBloque}">
          <video id="videoBloqueDosCluster" muted loop autoplay playsinline class="cluster" :class="{'cluster--ePIL': ocultaBloque}"
            v-if="segunda_seccion.imagen.webp.includes('webm') || segunda_seccion.imagen.default.includes('mov')">
            <source :src="urlVideoBloqueDos" :type="tipoVideoBloqueDos">
          </video>
          <picture v-else>
            <source :srcset="segunda_seccion.imagen.webp" type="image/webp" />
            <img width="619" height="329" loading="lazy" class="imagen_derecha" :src="segunda_seccion.imagen.default"
              :alt="segunda_seccion.altTitles.alt" :title="segunda_seccion.altTitles.title" />
          </picture>
        </div>

        <div class="contenido__informacion">
          <p class="informacion__texto" v-for="texto in texto_imagen_segundo" :key="texto" v-html="texto"></p>
        </div>
      </div>
    </div>

    <div v-if="ocultaBloque" class="bloque__tres">
      <div class="tres__titular">
        <div class="titular__encabezado">
          <p class="encabezado__texto">{{ tercera_seccion.encabezado }}</p>
        </div>

        <div class="titular__descripcion">
          <p class="descripcion__texto" v-for="texto in tercer_titular" :key="texto" v-html="texto"></p>
        </div>
      </div>

      <div class="tres__contenido">
        <div class="contenido__informacion">
          <p class="informacion__texto" v-for="texto in texto_superior_tercero" :key="texto" v-html="texto"></p>
        </div>       
        <div class="contenido__contenedor">
          <video id="videoBloqueDosClusterEpil" muted loop autoplay playsinline class="cluster"
            v-if="tercera_seccion.imagen.webp.includes('webm') || tercera_seccion.imagen.default.includes('mov')">
            <source :src="urlVideoBloqueDosEpil" :type="tipoVideoBloqueDosEpil">
          </video>
          <picture v-else>
            <source :srcset="tercera_seccion.imagen.webp" type="image/webp" />
            <img width="619" height="329" loading="lazy" class="imagen_derecha" :src="tercera_seccion.imagen.default"
              :alt="tercera_seccion.altTitles.alt" :title="tercera_seccion.altTitles.title" />
          </picture>
        </div>
        <div class="contenido__informacion">
          <p class="informacion__texto" v-for="texto in texto_inferior_tercero" :key="texto" v-html="texto"></p>
        </div>
      </div>
    </div>

    <div class="bloque__trigger-video">
      <videoBloqueDos />
    </div>
  </section>
</template>

<script>
import { useRoute } from 'vue-router'
import { defineAsyncComponent, inject, ref, computed } from "vue";
import { animacionScroll } from "@/mixins/AnimacionMixin.js";
export default {
  name: "bloquedosSecciones",
  mixins: [animacionScroll],
  components: {
    videoBloqueDos: defineAsyncComponent(() =>
      import(
        /* webpackChunkName: "videoBloqueDos" */ "../bloqueDosSecciones/VideoBloqueDos.vue"
      )
    ),
  },
  setup() {
    const bloque_dos = inject('jsonHome').value.bloque_dos
    const primera_seccion = bloque_dos.primera_seccion
    const segunda_seccion = bloque_dos.segunda_seccion
    const tercera_seccion = bloque_dos?.tercera_seccion ?? [];

    const route = useRoute();
    const ocultaBloque = computed(() => route.name == 'ePil' ? true : false);

    let urlVideoBloqueDos = ref(segunda_seccion.imagen.webp);
    let tipoVideoBloqueDos = ref("video/webm");

    let urlVideoBloqueDosEpil = ref(tercera_seccion.imagen?.webp ?? '');
    let tipoVideoBloqueDosEpil = ref("video/webm");

    const primer_titular = [...primera_seccion.titular];
    Array.from(primera_seccion.titular_destacado).forEach((element) => {
      Array.from(primer_titular).forEach((texto) => {
        var textoCorrecto = texto.replace(element, `<strong class="texto__destacado">${element}</strong>`);
        var index = primer_titular.indexOf(texto)
        primer_titular[index] = textoCorrecto;
      })
    });

    var texto_imagen = primera_seccion.texto_imagen;
    Array.from(primera_seccion.destacado_imagen).forEach((element) => {
      texto_imagen = texto_imagen.replace(element, `<strong class="pieFoto__destacado">${element}</strong>`);
    });

    const texto_descripcion = [...primera_seccion.texto_descripcion];
    Array.from(primera_seccion.destacado_imagen).forEach((element) => {
      Array.from(texto_descripcion).forEach((texto) => {
        var textoCorrecto = texto.replace(element, `<b>${element}</b>`);
        var index = texto_descripcion.indexOf(texto)
        texto_descripcion[index] = textoCorrecto;
      })
    });

    const segundo_titular = [...segunda_seccion.titular];
    Array.from(segunda_seccion.titular_destacado).forEach((element) => {
      Array.from(segundo_titular).forEach((texto) => {
        var textoCorrecto = texto.replace(element, `<strong class="texto__destacado">${element}</strong>`);
        var index = segundo_titular.indexOf(texto)
        segundo_titular[index] = textoCorrecto;
      })
    });

    const texto_imagen_segundo = [...segunda_seccion.texto_imagen];
    Array.from(segunda_seccion.destacado_imagen).forEach((element) => {
      Array.from(texto_imagen_segundo).forEach((texto) => {
        var textoCorrecto = texto.replace(element, `<b>${element}</b>`);
        var index = texto_imagen_segundo.indexOf(texto)
        texto_imagen_segundo[index] = textoCorrecto;
      })
    });

    const tercer_titular = [...tercera_seccion?.titular ?? []];
    Array.from(tercera_seccion?.titular_destacado ?? []).forEach((element) => {
      Array.from(tercer_titular).forEach((texto) => {
        var textoCorrecto = texto.replace(element, `<strong class="texto__destacado">${element}</strong>`);
        var index = tercer_titular.indexOf(texto)
        tercer_titular[index] = textoCorrecto;
      })
    });

    const texto_superior_tercero = [...tercera_seccion?.texto_superior ?? []];
    Array.from(tercera_seccion?.destacado_imagen ?? []).forEach((element) => {
      Array.from(texto_superior_tercero).forEach((texto) => {
        var textoCorrecto = texto.replace(element, `<b>${element}</b>`);
        var index = texto_superior_tercero.indexOf(texto)
        texto_superior_tercero[index] = textoCorrecto;
      })
    });
    const texto_inferior_tercero = [...tercera_seccion?.texto_inferior ?? []];
    Array.from(tercera_seccion?.destacado_imagen ?? []).forEach((element) => {
      Array.from(texto_inferior_tercero).forEach((texto) => {
        var textoCorrecto = texto.replace(element, `<b>${element}</b>`);
        var index = texto_inferior_tercero.indexOf(texto)
        texto_inferior_tercero[index] = textoCorrecto;
      })
    });

    return { 
      primera_seccion, segunda_seccion, tercera_seccion,  
      texto_imagen, texto_descripcion, ocultaBloque,
      primer_titular, segundo_titular, tercer_titular, 
      texto_imagen_segundo, texto_superior_tercero, texto_inferior_tercero,
      urlVideoBloqueDos, tipoVideoBloqueDos, urlVideoBloqueDosEpil, tipoVideoBloqueDosEpil }
  },
  mounted() {
    this.soportaVideo();
  },
  methods: {
    soportaVideo() {
      if (document.getElementById("videoBloqueDosCluster")) {
        var objVideo = document.getElementById("videoBloqueDosCluster");
        if (objVideo.canPlayType("video/webm") === "") {
          this.urlVideoBloqueDos = this.segunda_seccion.imagen.default;
          this.tipoVideoBloqueDos = "video/mp4";
        }
      }

      if (document.getElementById("videoBloqueDosClusterEpil")) {
        var objVideoEpil = document.getElementById("videoBloqueDosClusterEpil");
        if (objVideoEpil.canPlayType("video/webm") === "") {
          this.urlVideoBloqueDosEpil = this.tercera_seccion.imagen.default;
          console.log(this.urlVideoBloqueDosEpil);
          this.tipoVideoBloqueDosEpil = "video/mp4";
        }
      }

    },
  }
};
</script>