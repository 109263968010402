import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
import { computed } from '@vue/runtime-core';
import { useRoute } from 'vue-router';

export const animacionScroll = {
    mounted() {
        const route = useRoute();
        const valor = computed(() => route.name);
        if (valor.value.toLowerCase() != "epil") {
            this.scrollBloqueDos();
        } else {
            this.scrollBloqueDosEpil();
        }
    },
    methods: {
        getCookie(name) {
            const value = `; ${document.cookie}`;
            const parts = value.split(`; ${name}=`);
            if (parts.length === 2) return parts.pop().split(";").shift();
        },
        scrollBloqueDos() {
            
            gsap.matchMediaRefresh;
            let mm = gsap.matchMedia();
            
            mm.add("(min-width: 1280px)", () => {
                if (!navigator.userAgent.match(/iPhone|iPad|iPod/i)) {

                    var cirlulo = ".scroll_central__circulo";
                    var barraScroll = ".scroll_central__progress_bar--rojo";
                    var tamanioBloqueUno = document.querySelector("#BloqueUnoPrincipal").clientHeight;

                    const tl = gsap.timeline({
                        scrollTrigger: {
                            trigger: "#principal",
                            pin: true,
                            markers: false,
                            start: "top top",
                            end: "+=500%",
                            scrub: true,
                        },
                    })

                    /** Calcular computed top actual menos el height del anterior bloque para mover el bloque*/
                    tl.to("#BloquedosPrincipal", { top: -tamanioBloqueUno, duration: 2 });
                    tl.to(
                        cirlulo,
                        {
                            top: "0%",
                            backgroundColor: "#e30036",
                            borderColor: "#e30036",
                            duration: 2,
                        },
                        "-=2"
                    );

                    /** Funcionalidad del scroll doble en el bloque 2, movemos el primer contenido */
                    tl.to(".izq__titular", { top: "100%", duration: 2, delay: 0.5 });
                    tl.to(".izq__contenido", { top: "-100%", duration: 2 }, "-=2");

                    /**Animacion barra de progreso */
                    tl.to(barraScroll, { height: "45%", duration: 2 }, "-=2");
                    tl.to(cirlulo, { top: "45%", duration: 2 }, "-=2");
                    /**FIN Animacion barra de progreso */

                    /** Funcionalidad del scroll doble en el bloque 2, movemos el segundo contenido a la vista del usuario */
                    tl.to(".bloque__der", { opacity: 1, duration: 2 }, "-=2");
                    tl.to(
                        ".der__titular",
                        { top: "0%", duration: 2, opacity: 1 },
                        "-=2"
                    );
                    tl.to(
                        ".der__contenido",
                        { top: "0%", duration: 2, opacity: 1 },
                        "-=2"
                    );

                    /**Animacion barra de progreso */
                    tl.to(barraScroll, { height: "90%", duration: 2, delay: 2 }, "-=2");
                    tl.to(cirlulo, { top: "90%", duration: 2 }, "-=2");
                    /**FIN Animacion barra de progreso */

                    function playVideo() { document.querySelector(".video__estilo").play(); }
                    function pauseVideo() { document.querySelector(".video__estilo").pause(); }

                    /** Animacion mostrar el video*/
                    tl.to(
                        ".bloque__trigger-video",
                        {
                            top: "0%",
                            duration: 2,
                            onComplete: () => { playVideo(); },
                            onUpdate: () => { pauseVideo(); },
                        },
                        "-=2"
                    );
                    /** Fin de la animación de movimiento */

                    /**Animacion barra de progreso */
                    tl.to(barraScroll, { height: "100%", duration: 2 });
                    tl.to(
                        cirlulo,
                        {
                            top: "100%",
                            duration: 2,
                            onComplete: () => {
                                gsap.to("#BloqueTres", { top: 0, duration: 1 });
                                gsap.to("#footerBayer", { bottom: "100%", duration: 1 }, "-=1");
                                pauseVideo();
                            },
                            onUpdate: () => {
                                gsap.to("#BloqueTres", { top: "100%", duration: 1 });
                                gsap.to("#footerBayer", { bottom: "0%", duration: 1 }, "-=1");
                                playVideo();
                            },
                        },
                        "-=2"
                    );
                }
            });
            // mm.add("(min-width: 1023px) and (max-width:1400px) and (orientation: portrait) , (min-width: 1023px) and (max-width:1400px) and (orientation: landscape)", () => {
            //     console.log("hola")
            //     gsap.set(
            //         `#BloquedosPrincipal,
            //         .izq__titular,
            //         .izq__contenido,
            //         .bloque__der,
            //         .der__titular,
            //         .der__contenido,
            //         .bloque__trigger-video,
            //         #BloqueTres,
            //         #footerBayer`,
            //         { clearProps: "all" });
            // })
        },//fin scrollBloqueDos
        scrollBloqueDosEpil() {
            gsap.matchMediaRefresh;
            let mm = gsap.matchMedia();

            mm.add("(min-width: 1280px)", () => {
                var cirlulo = ".scroll_central__circulo";
                var barraScroll = ".scroll_central__progress_bar--rojo";
                var tamanioBloqueUno = document.querySelector("#BloqueUnoPrincipal").clientHeight;

                const tl = gsap.timeline({
                    scrollTrigger: {
                        trigger: "#principal",
                        pin: true,
                        markers: false,
                        start: "top top",
                        end: "+=500%",
                        scrub: true,
                    },
                })

                /** Calcular computed top actual menos el height del anterior bloque para mover el bloque*/
                tl.to("#BloquedosPrincipal", { top: -tamanioBloqueUno, duration: 2 });
                tl.to(
                    cirlulo,
                    {
                        top: "0%",
                        backgroundColor: "#e30036",
                        borderColor: "#e30036",
                        duration: 2,
                    },
                    "-=2"
                );

                /** Funcionalidad del scroll doble en el bloque 2, movemos el primer contenido */
                tl.to(".izq__titular", { top: "100%", duration: 2, delay: 0.5 });
                tl.to(".izq__contenido", { top: "-100%", duration: 2 }, "-=2");

                /**Animacion barra de progreso */
                tl.to(barraScroll, { height: "25%", duration: 2 }, "-=2");
                tl.to(cirlulo, { top: "25%", duration: 2 }, "-=2");
                /**FIN Animacion barra de progreso */

                /** Funcionalidad del scroll doble en el bloque 2, movemos el segundo contenido a la vista del usuario */
                tl.to(".bloque__der", { opacity: 1, duration: 2 }, "-=2");
                tl.to(
                    ".der__titular",
                    { top: "0%", duration: 2, opacity: 1 },
                    "-=2"
                );
                tl.to(
                    ".der__contenido",
                    { top: "0%", duration: 2, opacity: 1 },
                    "-=2"
                );

                /** Funcionalidad del scroll doble en el bloque 2, ocultamos el segundo contenido*/
                tl.to(".bloque__der", { opacity: 0, duration: 2 });
                tl.to(
                    ".der__titular",
                    { top: "100%", duration: 2, opacity: 1 },
                    "-=2"
                );
                tl.to(
                    ".der__contenido",
                    { top: "-100%", duration: 2, opacity: 1 },
                    "-=2"
                );

                /**Animacion barra de progreso */
                tl.to(barraScroll, { height: "45%", duration: 2 }, "-=2");
                tl.to(cirlulo, { top: "45%", duration: 2 }, "-=2");
                /**FIN Animacion barra de progreso */

                /** Funcionalidad del scroll doble en el bloque 2, movemos el tercer contenido a la vista del usuario */
                tl.to(".bloque__tres", { opacity: 1, duration: 2 }, "-=2");
                tl.to(
                    ".tres__titular",
                    { top: "0%", duration: 2, opacity: 1 },
                    "-=2"
                );
                tl.to(
                    ".tres__contenido",
                    { top: "0%", duration: 2, opacity: 1 },
                    "-=2"
                );

                /**Animacion barra de progreso */
                tl.to(barraScroll, { height: "90%", duration: 2, delay: 2 }, "-=2");
                tl.to(cirlulo, { top: "90%", duration: 2 }, "-=2");
                /**FIN Animacion barra de progreso */

                function playVideo() { document.querySelector(".video__estilo").play(); }
                function pauseVideo() { document.querySelector(".video__estilo").pause(); }

                /** Animacion mostrar el video*/
                tl.to(
                    ".bloque__trigger-video",
                    {
                        top: "0%",
                        duration: 2,
                        onComplete: () => { playVideo(); },
                        onUpdate: () => { pauseVideo(); },
                    },
                    "-=2"
                );
                /** Fin de la animación de movimiento */

                /**Animacion barra de progreso */
                tl.to(barraScroll, { height: "100%", duration: 2 });
                tl.to(
                    cirlulo,
                    {
                        top: "100%",
                        duration: 2,
                        onComplete: () => {
                            gsap.to("#BloqueTres", { top: 0, duration: 1 });
                            gsap.to("#footerBayer", { bottom: "100%", duration: 1 }, "-=1");
                            pauseVideo();
                        },
                        onUpdate: () => {
                            gsap.to("#BloqueTres", { top: "100%", duration: 1 });
                            gsap.to("#footerBayer", { bottom: "0%", duration: 1 }, "-=1");
                            playVideo();
                        },
                    },
                    "-=2"
                );
            });
            // mm.add("(min-width: 1023px) and (max-width:1400px) and (orientation: portrait) , (min-width: 1023px) and (max-width:1400px) and (orientation: landscape)", () => {
            //     gsap.set(
            //         `#BloquedosPrincipal,
            //         .izq__titular,
            //         .izq__contenido,
            //         .bloque__der,
            //         .der__titular,
            //         .der__contenido,
            //         .bloque__trigger-video,
            //         #BloqueTres,
            //         #footerBayer`,
            //         { clearProps: "all" });
            // })
        }//fin scrollBloqueDos
    }
}